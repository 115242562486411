require('intersection-observer');

// Variables

const homePage = document.querySelector("body.home");
const howPage = document.querySelector("body.page-template-template-how");
const contactPage = document.querySelector("body.page-template-template-contact");

const splashPage = document.querySelector(".splash-page");
const splashText = document.querySelector(".splash-page_text");
const fadeElements = document.querySelectorAll(".js-fade");
const header = document.querySelector("header");
const cookieBar = document.querySelector("div#cookie-law-info-bar");

const fadeTestimonial = document.querySelector(".js-fade-testimonial-slider");
const flkty = new Flickity('.js-testimonial', {
    autoPlay: 6000
});

const processSteps = document.querySelector(".js-fade-process")
const fadeProcessSteps = document.querySelectorAll(".js-fade-process_steps");

const locationContainer = document.querySelector(".js-fade-locations_container");
const fadeLocations = document.querySelectorAll(".js-fade-locations");

const mobileOpenBtn = document.querySelector(".mobile-menu_btn_open");
const mobileLightboxBtn = document.querySelector(".lightbox-menu_btn_open");
const mobileCloseBtn = document.querySelector(".mobile-menu_btn_close");
const mobileMenu = document.querySelector("#mobile-menu");
const mobileHeader = document.querySelector(".mobile-menu_header");
const mobileMainNav = document.querySelectorAll(".mobile-menu_nav li");
const mobileSocialNav = document.querySelector(".mobile-menu_nav-social");

const desktopHeading = document.querySelector(".grid-layout_heading-desktop");
const mobileHeading = document.querySelector(".grid-layout_heading-mobile");

const mobileMq = window.matchMedia('(max-width: 500px)');

// Functions

const animateCookieBar = function() {
    cookieBar.classList.add("animate");
}

const fadeInAnimation = function() {
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.intersectionRatio >= 0.1) {
                setTimeout( function(){ entry.target.classList.add("in-view"); }, 300 ); 
            } 
        });
    }, {
        threshold: [0, 0.1, 1]
    });

    fadeElements.forEach(elm => {
        observer.observe(elm);
    });
}

let prevScroll = window.pageYOffset;
const fadeHeader = function() {
    const currentScroll = window.pageYOffset;
    const headerHeight = header.offsetHeight;

    const headerLogo = document.querySelector(".logo-header");
    const headerType = header.dataset.color;
    darkLogoSrc = headerLogo.dataset.dark;
    lightLogoSrc = headerLogo.dataset.light;
    
    if(currentScroll <= headerHeight) {
        header.classList.remove("hide");
        header.classList.remove("shadow");
        if(headerType === "light") {
            header.classList.add("header-container_light");
            header.classList.remove("header-bg_light");
            headerLogo.src = lightLogoSrc;
            mobileOpenBtn.classList.add("text-white");
        }
    }
    else if (prevScroll < currentScroll) {
        // Hide header on scroll down
        header.classList.add("hide");
        header.classList.remove("shadow");
    } else {
        // Show header on scroll up
        header.classList.remove("hide");
        header.classList.add("shadow");
        if(headerType === "light") {
            header.classList.add("header-bg_light");
            header.classList.remove("header-container_light");
            headerLogo.src = darkLogoSrc;
            mobileOpenBtn.classList.remove("text-white");
        }
    }

    prevScroll = currentScroll;
}

const fadeInProcess = function() {
    z = 1
    const firstStep = fadeProcessSteps[0];

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.intersectionRatio >= 0.1) {
                setTimeout( function(){ 
                    entry.target.classList.add("in-view"); 
                    firstStep.classList.add("in-view");
                }, 300 ); 
                
                const interval = setInterval(function() {
                    const step = fadeProcessSteps[z];
                    step.classList.add("in-view");
                    z = z + 1;
                    if(z >= fadeProcessSteps.length){
                        z = 0;
                        clearInterval(interval);
                     }
                }, 600)
            } 
        });
    }, {
        threshold: [0, 0.1, 1]
    });

    observer.observe(processSteps);

}

const fadeInLocation = function() {
    z = 1
    const firstLocation = fadeLocations[0];

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.intersectionRatio >= 0.1) {
                setTimeout( function(){ 
                    entry.target.classList.add("in-view"); 
                    firstLocation.classList.add("in-view");
                }, 300 ); 
                
                const interval = setInterval(function() {
                    const location = fadeLocations[z];
                    location.classList.add("in-view");
                    z = z + 1;
                    if(z >= fadeLocations.length){
                        z = 0;
                        clearInterval(interval);
                     }
                }, 600)
            } 
        });
    }, {
        threshold: [0, 0.1, 1]
    });

    observer.observe(locationContainer);

}

const fadeInTestimonial = function() {
    flkty.pausePlayer();
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.intersectionRatio >= 0.25) {
                setTimeout( function(){ 
                    entry.target.classList.add("in-view"); 
                    flkty.playPlayer();
                }, 300 ); 
            } 
        });
    }, {
        threshold: [0, 0.1, 1]
    });

    observer.observe(fadeTestimonial);
}

const splashAnimation = function() {
    if(splashPage) {

        const timeline = gsap.timeline();

        const textArray = ["Expect", "great", "things."];
        let counter = 0;

        timeline
            .set(splashText, { opacity: 0 })
            .to(splashText, {opacity: 1 })
            .add(function() { 
                let interval = setInterval(changeText, 600);
                function changeText() {
                    splashText.innerHTML = textArray[counter];
                    counter++;
                    if(counter >= textArray.length) {
                        counter = 0;
                        clearInterval(interval);
                    }
                }
            }, 1)
            .add(function() { window.scrollTo(0, 0); }, 3)
            .add(function() { splashPage.classList.add("end") }, 4)
            .add(function() { fadeInAnimation(); }, 6)
            .add(function() { animateCookieBar(); }, 6)
            .add(function() { splashPage.style.display = "none" }, 10)

    }
}

const openMobileMenu = function() {
    const timeline = gsap.timeline();

    timeline
        .set(mobileMainNav, { opacity: 0 })
        .set(mobileSocialNav, { opacity: 0 })
        .add(function() { mobileMenu.classList.add("active"); }, 0)
        .add(function() { mobileHeader.classList.add("active"); }, 0.5)
        .to(mobileMainNav, { opacity: 1, stagger: 0.2 }, 1)
        .to(mobileSocialNav, { opacity: 1, duration: 0.3 }, 2)

    mobileOpenBtn.setAttribute("aria-expanded", "true");
}

const closeMobileMenu = function() {
    mobileMenu.classList.remove("active");
    mobileHeader.classList.remove("active");
    mobileOpenBtn.setAttribute("aria-expanded", "false");
}

const switchHeading = function() {
    if(mobileMq.matches && mobileHeading) {
        mobileHeading.classList.add("show");
        desktopHeading.style.display = "none";
    } else if (mobileHeading) {
        desktopHeading.style.display = "block";
        mobileHeading.classList.remove("show");
    } else {
        desktopHeading.style.display = "block";
    }
}

// Local Storage
function storageAvailable(type) {
    var storage;
    try {
        storage = window[type];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}

// Call Functions

if(homePage) {
    if (storageAvailable('sessionStorage') && sessionStorage.getItem('runOnce') == null) {
        splashAnimation();
        sessionStorage.setItem('runOnce', '1');
    } else {
        splashPage.style.display = "none";
        fadeInAnimation();
        animateCookieBar();
    }
} else {
    fadeInAnimation();
    animateCookieBar();
}

window.addEventListener("scroll", function() {
    fadeHeader();
});

if(howPage) {
    fadeInProcess();
}

if(contactPage) {
    fadeInLocation();
}

if(fadeTestimonial) {
    fadeInTestimonial();
}

if(mobileOpenBtn) {
    mobileOpenBtn.addEventListener("click", function() {
        openMobileMenu();
    });
    mobileCloseBtn.addEventListener("click", function() {
        closeMobileMenu();
    });
}

if(mobileLightboxBtn) {
    mobileLightboxBtn.addEventListener("click", function() {
        openMobileMenu();
    });
    mobileCloseBtn.addEventListener("click", function() {
        closeMobileMenu();
    });
}

switchHeading();

window.addEventListener("resize", function() {
    switchHeading();
})